import { type MetricOption } from 'types/addPrediction';

export const NFL_TEAM_FUTURES: MetricOption[] = [
  {
    label: 'Championship Winner',
    value: 'ChampionshipWinner',
  },
  {
    label: 'NFC Champion',
    value: 'NFCChampion',
  },
  {
    label: 'AFC Champion',
    value: 'AFCChampion',
  },
  {
    label: 'NFC North Division Winner',
    value: 'NFCNorthDivisionWinner',
  },
  {
    label: 'NFC South Division Winner',
    value: 'NFCSouthDivisionWinner',
  },
  {
    label: 'AFC West Division Winner',
    value: 'AFCWestDivisionWinner',
  },
  {
    label: 'NFC West Division Winner',
    value: 'NFCWestDivisionWinner',
  },
  {
    label: 'AFC East Division Winner',
    value: 'AFCEastDivisionWinner',
  },
  {
    label: 'AFC North Division Winner',
    value: 'AFCNorthDivisionWinner',
  },
  {
    label: 'AFC South Division Winner',
    value: 'AFCSouthDivisionWinner',
  },
  {
    label: 'NFC East Division Winner',
    value: 'NFCEastDivisionWinner',
  },
  {
    label: 'To Make The Playoffs',
    value: 'ToMakethePlayoffs',
  },
  {
    label: 'AFC North - Top 2',
    value: 'AFCNorth-Top2',
  },
  {
    label: 'AFC North Fourth Place',
    value: 'AFCNorthFourthPlace',
  },
  {
    label: 'AFC North Second Place',
    value: 'AFCNorthSecondPlace',
  },
  {
    label: 'AFC North Third Place',
    value: 'AFCNorthThirdPlace',
  },
  {
    label: 'AFC West Fourth Place',
    value: 'AFCWestFourthPlace',
  },
  {
    label: 'AFC West Second Place',
    value: 'AFCWestSecondPlace',
  },
  {
    label: 'AFC West Third Place',
    value: 'AFCWestThirdPlace',
  },
  {
    label: 'AFC West Top 2 Finish',
    value: 'AFCWestTop2Finish',
  },
  {
    label: 'NFC East Fourth Place',
    value: 'NFCEastFourthPlace',
  },
  {
    label: 'NFC East Second Place',
    value: 'NFCEastSecondPlace',
  },
  {
    label: 'NFC East Third Place',
    value: 'NFCEastThirdPlace',
  },
  {
    label: 'NFC East Top 2 Finish',
    value: 'NFCEastTop2Finish',
  },
  {
    label: 'NFC North Fourth Place',
    value: 'NFCNorthFourthPlace',
  },
  {
    label: 'NFC North Second Place',
    value: 'NFCNorthSecondPlace',
  },
  {
    label: 'NFC North Third Place',
    value: 'NFCNorthThirdPlace',
  },
  {
    label: 'NFC North Top 2 Finish',
    value: 'NFCNorthTop2Finish',
  },
  {
    label: 'NFC South Second Place',
    value: 'NFCSouthSecondPlace',
  },
  {
    label: 'AFC South Fourth Place',
    value: 'AFCSouthFourthPlace',
  },
  {
    label: 'AFC South Second Place',
    value: 'AFCSouthSecondPlace',
  },
  {
    label: 'AFC South Third Place',
    value: 'AFCSouthThirdPlace',
  },
  {
    label: 'AFC South Top 2 Finish',
    value: 'AFCSouthTop2Finish',
  },
  {
    label: 'NFC South Fourth Place',
    value: 'NFCSouthFourthPlace',
  },
  {
    label: 'NFC South Third Place',
    value: 'NFCSouthThirdPlace',
  },
  {
    label: 'NFC South Top 2 Finish',
    value: 'NFCSouthTop2Finish',
  },
  {
    label: 'AFC East Second Place',
    value: 'AFCEastSecondPlace',
  },
  {
    label: 'AFC East Third Place',
    value: 'AFCEastThirdPlace',
  },
  {
    label: 'AFC East Top 2 Finish',
    value: 'AFCEastTop2Finish',
  },
  {
    label: 'AFC East Fourth Place',
    value: 'AFCEastFourthPlace',
  },
  {
    label: 'NFC West Fourth Place',
    value: 'NFCWestFourthPlace',
  },
  {
    label: 'NFC West Second Place',
    value: 'NFCWestSecondPlace',
  },
  {
    label: 'NFC West Third Place',
    value: 'NFCWestThirdPlace',
  },
  {
    label: 'NFC West Top 2 Finish',
    value: 'NFCWestTop2Finish',
  },
  {
    label: 'To Win 10+ Games',
    value: 'ToWin10PlusGames',
  },
  {
    label: 'Team To Go 0-17',
    value: 'TeamToGo0And17',
  },
  {
    label: 'Team to Go 17-0',
    value: 'TeamtoGo17And0',
  },
  {
    label: 'To Win All Home Games',
    value: 'ToWinAllHomeGames',
  },
  {
    label: 'To Win All Away Games',
    value: 'ToWinAllAwayGames',
  },
  {
    label: 'To Lose All Home Games',
    value: 'ToLoseAllHomeGames',
  },
  {
    label: 'Worst Record',
    value: 'WorstRecord',
  },
  {
    label: 'Best Record',
    value: 'BestRecord',
  },
  {
    label: 'To Lose All Road Games',
    value: 'ToLoseAllRoadGames',
  },
  {
    label: 'Most Wins',
    value: 'MostWins',
  },
  {
    label: 'Least Wins',
    value: 'LeastWins',
  },
  {
    label: 'NFC #1 Seed',
    value: 'NFC1Seed',
  },
  {
    label: 'AFC #1 Seed',
    value: 'AFC1Seed',
  },
  {
    label: 'AFC Wildcard Team',
    value: 'AFCWildcardTeam',
  },
  {
    label: 'NFC Wildcard Team',
    value: 'NFCWildcardTeam',
  },
  {
    label: 'To Have A Winning Record',
    value: 'ToHaveAWinningRecord',
  },
  {
    label: 'To Reach AFC Championship Game',
    value: 'ToReachAFCChampionshipGame',
  },
  {
    label: 'To Reach NFC Championship Game',
    value: 'ToReachNFCChampionshipGame',
  },
  {
    label: 'To Lose All 6 Division Games',
    value: 'ToLoseAll6DivisionGames',
  },
  {
    label: 'To Win All 6 Division Games',
    value: 'ToWinAll6DivisionGames',
  },
];
